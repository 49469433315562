<template>
  <div class="row">
    <div class="col-12">
      <template>
        <component
          :is="component"
          :documentInfo.sync="documentInfo"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fire-detection-alarm-system-doc',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      documentInfo: {
        documentClassCd: 'ETDC', // FIRE_DETECTION_ATTACH
        title: '배출물의 처리 설계기준 및 명세',
      },
      component: null,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.component = () => import('@/pages/psi/documentMaster.vue');
      this.documentInfo.title = this.$comm.getLangLabel('LBL0010588'); // 배출물의 처리 설계기준 및 명세
    },
    reset() {
      Object.assign(this.$data.documentInfo, this.$options.data().documentInfo);
    },
  }
};
</script>